<template>
  <div>
    <div style="background-color: #fff">
      <div class="buttomnav">
        <div class="topbox flex-space-between">
          <div class="leftbox">
            <div class="logobox">
              <img src="@/assets/page/xlogo.png" alt="" />
            </div>
            <div class="listbox flex">
              <div class="Listitem flex" v-for="(item, index) in Listarr" :key="index" @click="itemclick(index)">
                <div class="conds">{{ item }}</div>
                <div class="shu" v-if="index != Listarr.length - 1"></div>
              </div>
            </div>
          </div>
          <div class="rightbox flex">
            <div style="margin-left: 15px;" v-for="(item,index) in numdata.qr_code" :key="index">
              <img :src="item.src" alt="" />
            </div>
            
            
          </div>
        </div>
        <div class="buttombox flex-space-between">
        <div class="buttomleft">{{numdata.copyright}}<a style=" color: #7b7a7a;" target="_blank" href="https://beian.miit.gov.cn/div>">{{ numdata.record_no }}</a> </div>
          <div class="buttomright">
            Co： {{ numdata.company }} / Add：{{ numdata.address }} / TEL： {{ numdata.phone }} /
            E-mail：{{ numdata.mailbox }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {footdata} from '../../service/request/homepage'
export default {
  data() {
    return {
      Listarr: ['板材', '家具', '艾德莱茵河', '案例', '企业动态', '联系我们'],
      numdata:{}
    }
  },
  components: {},
  mounted() {
    this.initdata()
  },
  methods: {
    itemclick(index) {
       if (index === 0) {
        this.$router.push({
          path: '/board'
        })
      } else if (index === 1) {
        this.$router.push({
          path: '/furniture',
          query: {
           id:0,
           index:0
        }
        })
      }  else if (index === 2) {
        this.$router.push({
          path: '/adam'
        })
      }else if (index === 3) {
        this.$router.push({
          path: '/casepage'
        })
      } else if (index === 4) {
        this.$router.push({
          path: '/enterprise'
        })
      }else if (index === 5) {
        this.$router.push({
          path: '/contact'
        })
      }
    },
    initdata() {
      console.log(footdata);
      footdata().then(res => {
        this.numdata=res.msg
      })
    }
  }
}
</script>
<style scoped lang="less">
.buttomnav {
  margin: 0 85px;
  .topbox {
    .leftbox {
      margin-top: 44px;
      .logobox {
        width: 266px;
        height: 57px;
        img {
          width: 266px;
          height: 57px;
        }
      }
      .listbox {
        margin-top: 48px;
        font-size: 16px;
        color: #000000;
        .Listitem {
          cursor: pointer;
          .conds{
            border-bottom: 1px solid #fff;
          }
          .shu {
            width: 1px;
            height: 10px;
            margin: 0 17px;
            background-color: #d1d1d1;
          }
        }
        .Listitem:hover .conds{
          color: #51907B;
          border-bottom: 1px solid #000;
        }
      }
    }
    .rightbox {
      margin-top: 44px;
      img {
        width: 160px;
        height: 160px;
      }
    }
  }
  .buttombox {
    margin-top: 25px;
    font-size: 14px;
    color: #7b7a7a;
    padding-bottom: 24px;
  }
}
</style>
