<template>
  <div>
    <el-backtop :bottom="400" :right="25"></el-backtop>
  </div>
</template>

<script>
export default {
  name: 'LaiyinPcGoback',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped></style>
