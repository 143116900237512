import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../pages/index.vue')
  },
  {
    path: '/index',
    name: 'indexs',
    component: () => import('../pages/index.vue')
  },
  // 板材
  {
    path: '/board',
    name: 'board',
    component: () => import('@/pages/board/board.vue')
  },
  // 板材列表
  {
    path: '/boarditem',
    name: 'boarditem',
    component: () => import('@/pages/boarditem/boarditem.vue')
  },
  // 板材详情
  {
    path: '/boardinfo',
    name: 'boardinfo',
    component: () => import('@/pages/boardinfo/boardinfo.vue')
  },
  // 家具列表
  {
    path: '/furniture',
    name: 'furniture',
    component: () => import('@/pages/furniture/furniture.vue')
  },
  // 家具系列详情
  {
    path: '/furnitureinfo',
    name: 'furnitureinfo',
    component: () => import('@/pages/furnitureinfo/furnitureinfo.vue')
  },
  // 艾德莱茵河
  {
    path: '/adam',
    name: 'adam',
    component: () => import('@/pages/adam/adam.vue')
  },
  // 联系我们
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/pages/contact/contact.vue')
  },
  // 案例
  {
    path: '/casepage',
    name: 'casepage',
    component: () => import('@/pages/casepage/casepage.vue')
  },
  // 案例详情
  {
    path: '/caseinfo',
    name: 'caseinfo',
    component: () => import('@/pages/caseinfo/caseinfo.vue')
  },
  // 企业动态
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import('@/pages/enterprise/enterprise.vue')
  },
  // 企业动态详情
  {
    path: '/enterpriseinfo',
    name: 'enterpriseinfo',
    component: () => import('@/pages/enterpriseinfo/enterpriseinfo.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',//history
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})
// router.beforeEach((to, from, next) => {
//   document.body.scrollTop = 0
//   // firefox
//   document.documentElement.scrollTop = 0
//   next()
// })
export default router
