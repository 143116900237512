import {service} from "../../service/index";

// 首页
export function indexdata(data) {
    return service.post({
        url: 'index/index',
        data
    })
}
// 底部
export function footdata(data) {
    return service.post({
        url: 'index/config',
        data
    })
}
// 板材页面
export function paneldata(data) {
    return service.post({
        url: 'index/panel',
        data
    })
}
// 板材列表页面
export function panelCasedata(data) {
    return service.post({
        url: 'index/panelCase',
        data
    })
}
// 板材详情页面
export function panelCaseDetail(data) {
    return service.post({
        url: 'index/panelCaseDetail',
        data
    })
}
// 家具tab
export function homedata(data) {
    return service.post({
        url: 'index/home',
        data
    })
}
// 家具list
export function homeSeries(data) {
    return service.post({
        url: 'index/homeSeries',
        data
    })
}
// 家具item
export function homeProduct(data) {
    return service.post({
        url: 'index/homeProduct',
        data
    })
}
// 家具下拉框
export function homeSeriesList(data) {
    return service.post({
        url: 'index/homeSeriesList',
        data
    })
}
// 艾德莱茵河
export function hostDetail(data) {
    return service.post({
        url: 'index/hostDetail',
        data
    })
}
// 案例列表
export function caseList(data) {
    return service.post({
        url: 'index/caseList',
        data
    })
}
// 案例详情
export function caseDetail(data) {
    return service.post({
        url: 'index/caseDetail',
        data
    })
}
// 企业动态
export function trends(data) {
    return service.post({
        url: 'index/trends',
        data
    })
}
// 企业动态详情
export function trendsDetail(data) {
    return service.post({
        url: 'index/trendsDetail',
        data
    })
}
// 关于企业
export function contact(data) {
    return service.post({
        url: 'index/contact',
        data
    })
}
// 板材tab
export function getPanelSeries(data) {
    return service.post({
        url: 'index/getPanelSeries',
        data
    })
}