<template>
  <div id="app">
    <navbar></navbar>
    <router-view />
    <bottmonav></bottmonav>
    <goback></goback>
  </div>
</template>
<script>
import navbar from '@/components/PublicPage/navbar.vue'
import goback from '@/components/PublicPage/goback.vue'
import bottmonav from '@/components/PublicPage/bottmonav.vue'
export default {
  data() {
    return {}
  },
  components: {
    navbar,
    bottmonav,
    goback
  },
  mounted() {
    if (this._isMobile()) {
      console.log(2222);
      // window.open('https://www.rhein-span.com/h5','_self')
      window.open('https://www.rheinspan.com.cn/h5','_self')
    }
  },
  methods: {
   _isMobile() {
    let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
   return flag
 }
}
}
</script>
<style lang="less">
@import url('./style/index.css');
@import url('./style/Publics.css');
@import url('./style/tx.css');

/* // 首页轮播图，指示器变成圆点 */
.el-carousel__indicator--horizontal .el-carousel__button {
  width: 6px;
  height: 6px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.5;
}
.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
}

.el-backtop {
  background-color: #999999;
}
.el-backtop:hover {
  background-color: #999999;
}
.el-icon-caret-top:before {
  content: '\e78f';
  color: #fff !important;
}

.rigthbox .el-input--suffix .el-input__inner{
      width: 175px;
      height: 50px;
      background-color: #3E907C;
      color: #fff;
      font-size: 18px;
      padding-left: 15px;
     
    }
.rigthbox  .el-select .el-input .el-select__caret{
      color: #fff;
      width: 16px;
      margin-right: 10px;
      /* height: 8px; */
}
.rigthbox  {
  input:-moz-placeholder {   
    color: #fff;   
  }
  input:-ms-input-placeholder {   
    color: #fff;   
  }   
  
input::-webkit-input-placeholder {   
    color: #fff;   
  }
   
} 


 .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #3E907C;
  }
</style>
