<template>
  <div>
    <div class="maxbox"  :style="isbackcolor?bordershow?'background-color: #fff; transition: 0.6s all;box-shadow: 0px 1px 1px rgba(0,0,0,0.2)':'background-color: #fff; transition: 0.6s all;':''">
      <!-- :style="isbackcolor?'background-color: #fff; transition: 0.6s all;box-shadow: 0px 1px 1px rgba(0,0,0,0.2)':''" -->
      <div class="topbox flex-space-between">
        <div class="leftLogo animate__animated animate__backInLeft" @click="logoclick">
          <img :src="logoshow ? require('@/assets/page/logo.png') : require('@/assets/page/heilogo.png')" alt="" />
        </div>
        <div class="rightFold" @click="sidebar" v-show="iscbtab">
          <!-- <img src="@/assets/page/chenak.png" alt="" /> -->
          <img :src="logoshow ? require('@/assets/page/chenak.png') : require('@/assets/page/he.png')" alt="" />
        </div>
      </div>
    </div>

    <!-- 展开 -->
    <el-drawer :size="size" :visible.sync="drawer" @close="closecheng" :with-header="false">
      <div class="drawer_box flex">
        <div class="left" v-show="Sidedrawer">
          <div class="Listbox">
            <div @click="furniture(index,item.id)" class="Listitem" v-for="(item, index) in cbList" :key="index">
              {{ item.en_name }} {{ item.name}}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="delete_box" @click="deleteclick">
            <img src="@/assets/page/shanchu.png" alt="" />
          </div>
          <div class="Listbox">
            <div class="listitem"></div>
            <div
              @click="itemclick(index)"
              :class="activeindex == index ? 'activecolor Listitem' : 'Listitem'"
              v-for="(item, index) in List"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {homedata} from '../../service/request/homepage'
export default {
  data() {
    return {
      logoshow: true, //true是首页白色，false是黑色logo
      drawer: false, // 侧边弹框
      bordershow:true,//下边框
      Sidedrawer: false, //二级侧边框开关
      size: '388px', //侧边弹框的宽度
      activeindex: null, //高亮判断
      iscbtab: true, //侧边是否显示
      isbackcolor:false,//是否有背景色
      List: ['首页', '板材', '家具', '艾德莱茵河', '案例', '企业动态', '联系我们'],
      cbList: ['All全部', 'style 风格集合', 'Color 彩色集合', 'Texture 纹理集合', 'Fashion 时尚集合']
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        console.log(val)
        // 家具不需要下边框
        if (val.path == '/furniture'||val.path == '/enterprise') {
          this.bordershow=false
        } else {
          this.bordershow=true
        }

        if (
          val.path == '/board' ||
          val.path == '/boarditem' ||
          val.path == '/boardinfo' ||
          val.path == '/furniture' ||
          val.path == '/enterprise' ||
          val.path == '/enterpriseinfo' ||
          val.path == '/contact' 
         
         
        ) {
          this.logoshow = false
        } else {
          this.logoshow = true
        }
      },
      // 深度观察监听
      deep: true
      // immediate: true
    }
  },
  
  components: {},
  mounted() {
    this.homeSeries()//菜单数据
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(){
      var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
      // console.log(this.$route);
      // 判断滚动的距离
      if(top>=200){
        this.isbackcolor=true
        // 判断当前路由
        if(this.$route.path=='/index'){
          this.logoshow=false
        }else if(this.$route.path=='/adam'){
          this.logoshow=false
        }else if(this.$route.path=='/casepage'){
          this.logoshow=false
        }
        else if(this.$route.path=='/caseinfo'){
          this.logoshow=false
        }
       
        else if(this.$route.path=='/furnitureinfo'){
          this.logoshow=false
        } else if(this.$route.path=='/'){
          this.logoshow=false
        }
      }else{
        this.isbackcolor=false
        if(this.$route.path=='/index'){
          this.logoshow=true
        }else if(this.$route.path=='/adam'){
          this.logoshow=true
        }
        else if(this.$route.path=='/furnitureinfo'){
          this.logoshow=true
        }
        else if(this.$route.path=='/casepage'){
          this.logoshow=true
        }
        else if(this.$route.path=='/caseinfo'){
          this.logoshow=true
        }
        else if(this.$route.path=='/'){
          this.logoshow=true
        }
      }
    },
    // 侧边弹框打开
    sidebar() {
      this.iscbtab = false

      this.drawer = true
    },
    // 家具菜单
    homeSeries() {
      homedata().then(res => {
        // console.log('菜单', res);
        this.cbList=res.msg.list
      })
    },
    // 侧边栏
    deleteclick() {
      // this.iscbtab=false
      this.drawer = false
    },
    // 点击item
    itemclick(index) {
      if (index === 0) {
        this.$router.push({
          path: 'index'
        })
        this.drawer = false
      } else if (index === 1) {
        this.$router.push({
          path: '/board'
        })
        this.drawer = false
        console.log(index)
      } else if (index === 2) {
        this.activeindex = index
        this.size = '776px'
        this.Sidedrawer = true
      } else if (index === 3) {
        this.$router.push({
          path: '/adam'
        })
        this.drawer = false
      } else if (index === 4) {
        this.$router.push({
          path: '/casepage'
        })
        this.drawer = false
      } else if (index === 5) {
        this.$router.push({
          path: '/enterprise'
        })
        this.drawer = false

        console.log(index)
      } else if (index === 6) {
        this.$router.push({
          path: '/contact'
        })
        this.drawer = false
        this.drawer = false
        console.log(index)
      }
      setTimeout(() => {
        this.iscbtab = true
      }, 300)
    },
    // 家具系列
    furniture(index,id) {
      console.log(index)
      this.$router.push({
        path: '/furniture',
        query: {
          id,
          index
        }
      })
      this.drawer = false
    },
    logoclick() {
      this.$router.push({
        path: 'index'
      })
    },
    // 关闭弹框的回调
    closecheng() {
      this.size = '388px'
      this.Sidedrawer = false
      this.activeindex = null
      setTimeout(() => {
        this.iscbtab = true
      }, 300)
    }
  }
}
</script>
<style scoped lang="less">
.maxbox {
  transition: 0.6s all;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 130px;
  z-index: 99;
  /* background-color: #bfa; */
}
.topbox {
  margin: 36px  95px  0 85px;

  .leftLogo {
    cursor: pointer;
    width: 260px;
    height: 57px;
    img {
      width: 260px;
    height: 57px;
    }
  }
  .rightFold {
    cursor: pointer;
    width: 26px;
    height: 22px;
    line-height: 60px;
    img {
      width: 26px;
      height: 22px;
    }
  }
}
.drawer_box {
  position: relative;
  height: 100%;
  .left {
    width: 388px;
    height: 100%;
    border-right: 1px solid #999999;
    .Listbox {
      padding-top: 184px;
      text-align: right;
      font-size: 28px;
      color: #999999;
      .Listitem {
        width: 302px;
        margin: 0 50px 38px 66px;
      }
      .Listitem:hover {
        color: #000000;
        // border-bottom: 1px solid #000000;
      }
    }
  }
  .right {
    width: 388px;
    height: 100%;
    .delete_box {
      position: absolute;
      top: 78px;
      right: 95px;
      width: 22px;
      height: 22px;
      
      img {
        width: 22px;
        height: 22px;
      }
    }
    .Listbox {
      padding-top: 184px;
      text-align: right;
      font-size: 28px;
      color: #999999;
      .Listitem {
        width: 164px;
        margin: 0 85px 38px 141px;
      }
      .activecolor {
        color: #000000;
      }
      .Listitem:hover {
        color: #000000;
        // border-bottom: 1px solid #000000;
      }
    }
  }
}
</style>
